import moment from 'moment'

export const FormateaNumero = (n:string) => n.substr(0,3) + '-' + n.substr(3,3) + '-' + n.substr(6,4) 


export const fecha = (f:any)=>{ return moment(f).format('DD [DE] MMMM [DE] YYYY') }
export const edad = (f:any)=>{ return moment().diff( f , 'years') }




export const getsign = (fecha_nacimiento:any)=>{
      var date : any = new Date(fecha_nacimiento)
      var d = date.getDate();
      var m = date.getMonth();
      // var a = date.getYear();
 
      if ((d >= 23 && m === 10) || (d <= 22 && m === 11)){
        return "Sagitario";
      }else if ((d >= 23 && m === 11) || (d <= 20 && m === 0)){
        return "Capricornio";
      }else  if ((d >= 21 && m === 0) || (d <= 19 && m === 1)){
        return "Acuario";
      }else  if ((d >= 20 && m === 1) || (d <= 20 && m === 2)){
        return "Piscis";
      }else if ((d >= 21 && m === 2) || (d <= 21 && m === 3)){
        return "Aries";
      }else  if ((d >= 22 && m === 3) || (d <= 21 && m === 4)){
        return "Tauro";
      }else if ((d >= 22 && m === 4) || (d <= 21 && m === 5)){
        return "Géminis";
      }else if ((d >= 22 && m === 5) || (d <= 22 && m === 6)){
        return "Cáncer";
      }else if ((d >= 23 && m === 6) || (d <= 22 && m === 7)){
        return "Leo";
      }else if ((d >= 23 && m === 7) || (d <= 22 && m === 8)){
        return "Virgo";
      }else if ((d >= 23 && m === 8) || (d <= 22 && m === 9)){
        return "Libra";
      }else if ((d >= 23 && m === 9) || (d <= 22 && m === 10)){
        return "Escorpio";
      }
      return 'NO'
    }

export const romanize = (num:any)=>{
    if (isNaN(num))
        return NaN;
    var digits : any = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}



export const formatNumberWithCommas = (number:number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const sumField =(data:any, field:any) => {
    return data.reduce((accumulator:any, current:any) => {
        return accumulator + (current[field] || 0);
    }, 0);
}

// export const obtenerClasePorAvance = (avance:any) => {
//     if (avance < 0 || avance > 100) {
//         // throw new Error("El avance debe estar entre 0 y 100.");
//       return 'clase-rojo-1'
//     }

//     const rango = Math.floor(avance / 10);

//     switch (rango) {
//         case 0: return 'clase-rojo-1'; // 0-10
//         case 1: return 'clase-rojo-2'; // 11-20
//         case 2: return 'clase-rojo-3'; // 21-30
//         case 3: return 'clase-amarillo-1'; // 31-40
//         case 4: return 'clase-amarillo-2'; // 41-50
//         case 5: return 'clase-amarillo-3'; // 51-60
//         case 6: return 'clase-verde-1'; // 61-70
//         case 7: return 'clase-verde-2'; // 71-80
//         case 8: return 'clase-verde-3'; // 81-90
//         case 9: return 'clase-verde-4'; // 91-100
//         default: return 'clase-verde-4'; // Para cualquier caso inesperado
//     }
// }



export const getPercentageClass = (porcentaje:number) => {
    if (porcentaje <= 10) return 'percentage-0-10';
    if (porcentaje <= 30) return 'percentage-10-30';
    if (porcentaje <= 50) return 'percentage-30-50';
    if (porcentaje <= 70) return 'percentage-50-70';
    if (porcentaje <= 90) return 'percentage-70-90';
    return 'percentage-90-100'; // Para 90 a 100%
  };