import React, { useState, useRef, useEffect } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Card, ListGroup } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { SERVER_ } from '../../config'


import 'ol/ol.css';
import { Map, View } from 'ol';
import { Tile as TileLayer } from 'ol/layer';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Icon } from 'ol/style';

import moment from 'moment'

const ModalDetalleFolio = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, folio } = params

  const mapRef : any = useRef();

  useEffect(() => {
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        // Capa para el marcador
        new VectorLayer({
          source: new VectorSource({
            features: [new Feature(new Point(fromLonLat([folio.localizacion?.longitude, folio.localizacion?.latitude])))]
          }),
          style: new Style({
            image: new Icon({
              src: './img/marker.png',
              scale: 1 // Ajusta el tamaño del ícono según lo necesites
            })
          })
        }),
      ],
      view: new View({
        center: fromLonLat([folio.localizacion?.longitude, folio.localizacion?.latitude]),
        zoom: 15, // Ajusta el zoom según lo necesites
      }),
    });

    return () => map.setTarget(undefined); // Cleanup
  }, [folio.localizacion?.longitude, folio.localizacion?.latitude]);


  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Detalles del Folio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(folio)
          }



          <div className="row">
            <div className="col-sm-4">

                <Card className="nm">
                  <Card.Body>
                    <Card.Subtitle className="mb-2">Folio: {folio.folio}</Card.Subtitle>
                    <Card.Text>
                      Censó: {moment(folio.fecha_censo).format('DD/MMMM/YYYY hh:mm A').toUpperCase()}
                    </Card.Text>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroup.Item>Adulto: {folio.adulto ? 'Sí' : 'No'}</ListGroup.Item>
                    <ListGroup.Item>Discapacidad: {folio.discapacidad ? 'Sí' : 'No'}</ListGroup.Item>
                    <ListGroup.Item>Sexo: {folio.sexo === 'H' ? 'Hombre' : 'Mujer'}</ListGroup.Item>
                    <ListGroup.Item>Capturó: <b>{folio.nombres}</b> {folio.paterno} {folio.materno}</ListGroup.Item>
                    <ListGroup.Item>Migración: {moment(folio.fecha_creacion).format('DD/MMMM/YYYY hh:mm A').toUpperCase()}</ListGroup.Item>
                    <ListGroup.Item><small>{folio.uuid_censo}</small></ListGroup.Item>
                  </ListGroup>
                </Card>


                <div className="mt-4" ref={mapRef} style={{ width: '100%', height: '400px' }} />


                  <a href={`https://www.google.com/maps?q=${folio.localizacion.latitude},${folio.localizacion.longitude}`} target="_blank">
                    Abrir Google Maps
                  </a>


                {
                  // JSON.stringify(folio.localizacion)
                }




            </div>
            <div className="col-sm-4">
                <h6>Fachada de la Casa</h6>
                <img src={`${SERVER_}censo/${folio.uuid_censo}/imagen_casa`} width="100%" />
            </div>
            <div className="col-sm-4">
                <h6>Foto del Censo</h6>
                <img src={`${SERVER_}censo/${folio.uuid_censo}/imagen_marca`} width="100%" />
            </div>
          </div>




        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalDetalleFolio;