import axios from "axios";
import { SERVER_ } from  '../../config';

class AuthService {

  selectIsAuthenticated : Boolean = false;

  constructor(){
      this.selectIsAuthenticated = this.IsAuthenticated()
  }

  login(username:String, password:String) {
    return axios
      .post( SERVER_ + "authenticate", {
        username,
        password
      })
      .then((response:any) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("rol", JSON.stringify(response.data.rol));
          localStorage.setItem("version_app", (response.data.version_app));
          localStorage.setItem("accessToken", JSON.stringify(response.data.accessToken));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("rol");
    localStorage.removeItem("accessToken");
  }

  register(username:String, email:String, password:String) {
    return axios.post( SERVER_ + "signup", {
      username,
      email,
      password
    });
  }

  IsAuthenticated() {
    return (JSON.parse(localStorage.getItem('user')!)) ? true : false;
  }

  getCurrentUser() {
    const USUARIO = JSON.parse(localStorage.getItem('user')!)||{};
    // console.log(localStorage.getItem('rol'))
    USUARIO.role = localStorage.getItem('rol')
    return USUARIO
  }

  getToken(){
    return JSON.parse(localStorage.getItem('accessToken')!)
  }
}

// Asignar la instancia de AuthService a una variable antes de exportar
const authServiceInstance = new AuthService();

export default authServiceInstance;