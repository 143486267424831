import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { formatNumberWithCommas } from '../../services/funcionesPublicas'


const Regiones = () =>{


  const [ avance, setAvance ] = useState<any>({})
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarSeccion = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`reportes/avances/regiones`)
      setAvance(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }



  React.useEffect(() => {
    cargarSeccion()
  }, []);



      return (
          <div className="mt-4">


                    <h6>Regiones</h6>


                        <h1>Total</h1>

                        <div className="row mb-4">
                          <div className="col">
                            <strong>Folios:</strong>
                            <h4>{formatNumberWithCommas((avance.region_1?.total_folios||0) + (avance.region_2?.total_folios||0))}</h4>
                          </div>
                          <div className="col">
                            <strong>Meta:</strong>
                            <h4>{formatNumberWithCommas( (avance.region_1?.meta_folios||0) + (avance.region_2?.meta_folios||0) + (avance.region_1?.meta_folios_pcd||0) + (avance.region_2?.meta_folios_pcd||0) )}</h4>
                          </div>
                          <div className="col adulto">
                            <strong>PAM:</strong>
                            <h4>{formatNumberWithCommas((avance.region_1?.total_folios_pam||0) + (avance.region_2?.total_folios_pam||0))}</h4>
                          </div>
                          <div className="col adulto">
                            <strong>Meta PAM:</strong>
                            <h4>{formatNumberWithCommas((avance.region_1?.meta_folios||0) + (avance.region_2?.meta_folios||0))}</h4>
                          </div>
                          <div className="col discapacidad">
                            <strong>PCD:</strong>
                            <h4>{formatNumberWithCommas((avance.region_1?.total_folios_pcd||0) + (avance.region_2?.total_folios_pcd||0))}</h4>
                          </div>
                          <div className="col discapacidad">
                            <strong>Meta PCD:</strong>
                            <h4>{formatNumberWithCommas((avance.region_1?.meta_folios_pcd||0) + (avance.region_2?.meta_folios_pcd||0))}</h4>
                          </div>
                          <div className="col vivienda">
                            <strong>Viviendas:</strong>
                            <h4>{formatNumberWithCommas((avance.region_1?.total_viviendas||0) + (avance.region_2?.total_viviendas||0))}</h4>
                          </div>
                        </div>



                    <div className="row">
                      <div className="col-6">
                        <h1>REGIÓN 1</h1>

                        <h2>Data</h2>
                        <ul>
                          <li><strong>Folios:</strong> {formatNumberWithCommas(avance.region_1?.total_folios||0)}</li>
                          <li><strong>Registrados PAM:</strong> {formatNumberWithCommas(avance.region_1?.total_folios_pam||0)}</li>
                          <li><strong>Meta PAM:</strong> {formatNumberWithCommas(avance.region_1?.meta_folios||0)}</li>
                          <li><strong>Registrados PCD:</strong> {formatNumberWithCommas(avance.region_1?.total_folios_pcd||0)}</li>
                          <li><strong>Meta PCD:</strong> {formatNumberWithCommas(avance.region_1?.meta_folios_pcd||0)}</li>
                          <li><strong>Viviendas:</strong> {formatNumberWithCommas(avance.region_1?.total_viviendas||0)}</li>
                        </ul>

                        <h3>Avances</h3>
                        <ul>
                          <li><strong>Meta de Folios</strong> { formatNumberWithCommas(Math.round(( ( (avance.region_1?.total_folios||0) * 100 ) / avance.region_1?.meta_folios||0 ))) } %</li>
                          <li><strong>Meta de Registrados PAM:</strong> { formatNumberWithCommas(Math.round(( ( (avance.region_1?.total_folios_pam||0) * 100 ) / avance.region_1?.meta_folios||0 ))) } %</li>
                          <li><strong>Meta de Registrados PCD:</strong> { formatNumberWithCommas(Math.round(( ( (avance.region_1?.total_folios_pcd||0) * 100 ) / avance.region_1?.meta_folios_pcd||0 ))) }%</li>
                        </ul>


                        <h3>Por Municipios</h3>
                        <table className="table table-sm table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Municipio</th>
                              <th>Áreas</th>
                              <th>Meta</th>
                              <th>🧓🏿</th>
                              <th>Meta</th>
                              <th>♿️</th>
                              <th>Meta</th>
                              <th>🏠</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              avance.region_1?.acumulados_municipios.map((municipio:any,indice_municipio:number) => (
                              <tr key={municipio.id}>
                                <td className="v" >
                                  <p className="nm">{municipio.municipio}</p>
                                </td>
                                <td className="v" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.secciones)}</p>
                                </td>
                                <td className="v" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta)}</p>
                                </td>
                                <td className="v adulto" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.folios_pam)}</p>
                                </td>
                                <td className="v adulto" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta_pam)}</p>
                                </td>
                                <td className="v discapacidad" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.folios_pcd)}</p>
                                </td>
                                <td className="v discapacidad" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta_pcd)}</p>
                                </td>
                                <td className="v viviendas" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.viviendas)}</p>
                                </td>
                              </tr>
                            ))
                            }
                          </tbody>
                        </table>

                        {
                          // JSON.stringify(avance.region_1?.acumulados_municipios)
                        }

{
  // JSON.stringify(`${window.location.protocol}//${window.location.hostname}`)
}


                        {
                          // JSON.stringify(avance.region_1)
                        }

                      </div>
                      <div className="col-6">
                        <h1>REGIÓN 2</h1>


                        <h2>Data</h2>
                        <ul>
                          <li><strong>Folios:</strong> {formatNumberWithCommas(avance.region_2?.total_folios||0)}</li>
                          <li><strong>Registrados PAM:</strong> {formatNumberWithCommas(avance.region_2?.total_folios_pam||0)}</li>
                          <li><strong>Meta PAM:</strong> {formatNumberWithCommas(avance.region_2?.meta_folios||0)}</li>
                          <li><strong>Registrados PCD:</strong> {formatNumberWithCommas(avance.region_2?.total_folios_pcd||0)}</li>
                          <li><strong>Meta PCD:</strong> {formatNumberWithCommas(avance.region_2?.meta_folios_pcd||0)}</li>
                          <li><strong>Viviendas:</strong> {formatNumberWithCommas(avance.region_2?.total_viviendas||0)}</li>
                        </ul>

                        <h3>Avances</h3>
                        <ul>
                          <li><strong>Meta de Folios</strong> { formatNumberWithCommas(Math.round(( ( (avance.region_2?.total_folios||0) * 100 ) / avance.region_2?.meta_folios||0 ))) } %</li>
                          <li><strong>Meta de Registrados PAM:</strong> { formatNumberWithCommas(Math.round(( ( (avance.region_2?.total_folios_pam||0) * 100 ) / avance.region_2?.meta_folios||0 ))) } %</li>
                          <li><strong>Meta de Registrados PCD:</strong> { formatNumberWithCommas(Math.round(( ( (avance.region_2?.total_folios_pcd||0) * 100 ) / avance.region_2?.meta_folios_pcd||0 ))) }%</li>
                        </ul>

                        <h3>Por Municipios</h3>
                        <table className="table table-sm table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Municipio</th>
                              <th>Áreas</th>
                              <th>Meta</th>
                              <th>🧓🏿</th>
                              <th>Meta</th>
                              <th>♿️</th>
                              <th>Meta</th>
                              <th>🏠</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              avance.region_2?.acumulados_municipios.map((municipio:any,indice_municipio:number) => (
                              <tr key={municipio.id}>
                                <td className="v" >
                                  <p className="nm">{municipio.municipio}</p>
                                </td>
                                <td className="v" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.secciones)}</p>
                                </td>
                                <td className="v" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta)}</p>
                                </td>
                                <td className="v adulto" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.folios_pam)}</p>
                                </td>
                                <td className="v adulto" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta_pam)}</p>
                                </td>
                                <td className="v discapacidad" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.folios_pcd)}</p>
                                </td>
                                <td className="v discapacidad" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta_pcd)}</p>
                                </td>
                                <td className="v viviendas" style={{width:80}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.viviendas)}</p>
                                </td>
                              </tr>
                            ))
                            }
                          </tbody>
                        </table>





                        {
                          // JSON.stringify(avance.region_2)
                        }

                      </div>
                    </div>
                    





                    <div className="row">
                      <div className="col-6">


                      </div>
                      <div className="col-6">


                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">


                      </div>
                      <div className="col-6">


                      </div>
                    </div>






                    <div className="row">
                      <div className="col-6">


                        <p>
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_regiones.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=secciones_avance_censo_region_1,region_1&CRS=EPSG%3A3857&WIDTH=1564&HEIGHT=1029&BBOX=-10332543.222481707%2C1980312.7796655516%2C-9724034.120427856%2C2380668.1927176067`} />
                        </p>


                      </div>
                      <div className="col-6">

                        <p>
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_regiones.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=secciones_avance_censo_region_2,region_2&CRS=EPSG%3A3857&WIDTH=1074&HEIGHT=690&BBOX=-10331545,2008185,-9935165,2243544`} />
                        </p>


                      </div>
                    </div>



                      <p className="nm text-center">
                        <small>
                            entre 0 y 10% <i className="fa fa-circle" style={{color:'rgb(255 0 0)'}}/> {' '}
                            entre 10 y 30%  <i className="fa fa-circle" style={{color:'rgb(255 165 0)'}}/>{' '}
                            entre 30 y 50%  <i className="fa fa-circle" style={{color:'rgb(255 255 0)'}}/>{' '}
                            entre 50 y 70%  <i className="fa fa-circle" style={{color:'rgb(173 255 47)'}}/>{' '}
                            entre 70 y 90%  <i className="fa fa-circle" style={{color:'rgb(0 128 0)'}}/>{' '}
                            entre 90 y 100%  <i className="fa fa-circle" style={{color:'rgb(0 255 0)'}}/>
                        </small>
                      </p>


                    <div className="row">
                      <div className="col-6">

                        <p>
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_regiones.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=region_1,heatmap&CRS=EPSG%3A3857&WIDTH=1564&HEIGHT=1029&BBOX=-10332543.222481707%2C1980312.7796655516%2C-9724034.120427856%2C2380668.1927176067`} />
                        </p>

                      </div>
                      <div className="col-6">


                        <p>
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_regiones.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=region_2,heatmap_region_2&CRS=EPSG%3A3857&WIDTH=1564&HEIGHT=1029&BBOX=-10305059.49045759%2C2014480.4297238016%2C-9931456.880865753%2C2260284.192684161`} />
                        </p>

                      </div>
                    </div>




                    <div className="row">
                      <div className="col-6">


                      </div>
                      <div className="col-6">


                      </div>
                    </div>

                      

          </div>

      );
  

}

export default Regiones
