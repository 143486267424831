import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import PasswordChecklist from "react-password-checklist"


import Dialog from '../../services/Dialogs'



const ModalResetPasswordUsuario = ( params : any ) => {

    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")

    const dialog = new Dialog();

    const [send, setSend] = useState(false);

  const { show, handleClose, usuario } = params

    // const [show, setShow] = useState(false);

  const cambiarContrasena = async () =>{ 
      
      try{
          if(await dialog.confirm('¿Estás seguro?',{}) ){
              // setShow(false);
              
              await RequestService.update('usuarios/actualizar_password/'+usuario.uuid,{ psw : password })
              toast.warning('La contraseña fue modificada')
              handleClose()
              // setTimeout(()=>{
              //     // AuthService.logout();
              //     // window.location.reload();
              // },1500)
          }
      }
      catch(e){
          toast.error('Error al cambiar la contraseña')
      }
  }


  const [ ver_contra, setVerContra ] = useState<boolean>(false)


  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Cambiar Contraseña</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <p className="nm">Usuario:</p>
          <h4 className="nm">{usuario.nombres} {usuario.paterno} {usuario.materno} </h4>

          <div className="alert alert-danger mt-3" role="alert">
            <i className="fa fa-warning" /> Recuerda Compartir la contraseña con el usuario una vez hecho el cambio de esta
          </div>



        <form>

            <div className="form-group mb-3">
                <label className="form-label">Password:</label>
                <input className="form-control" type={ ver_contra ? 'text' : 'password' } onChange={e => setPassword(e.target.value)} />
            </div>
            <div className="form-group mb-3">
                <label className="form-label">Repetir Password:</label>
                <input className="form-control" type={ ver_contra ? 'text' : 'password' } onChange={e => setPasswordAgain(e.target.value)} />
            </div>


          <Form.Check className="mb-3" type={'checkbox'} checked={ver_contra} onChange={(e:any)=>{setVerContra(e.target.checked)}} id={`default-ver-contr`} label={`Ver contraseña`} />


            <PasswordChecklist
                rules={["minLength","specialChar","number","capital","match"]}
                messages={{
                    minLength: "La contraseña tiene más de 8 carácteres.",
                    specialChar: "La contraseña debe contener carácteres especiales.",
                    number: "La contraseña debe tener un número.",
                    capital: "La contraseña debe tener una letra mayúscula.",
                    match: "Las contraseñas coinciden.",
                }}
                minLength={5}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => {
                    setSend(!isValid)
                }}
            />
        </form>



          {
            // JSON.stringify(usuario)
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" type="button" disabled={send} onClick={cambiarContrasena}>Cambiar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalResetPasswordUsuario;