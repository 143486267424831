import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table } from 'react-bootstrap'

import { SERVER_ } from '../../config'



import ModalRegistroIncidencias from './ModalRegistroIncidencias'

import ModalReporteIncidencias from './ModalReporteIncidencias'



import './asistencias.scss'


const Asistencias = () =>{


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 30
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`asistencia_personal/-/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  // const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  // const todos = () =>{
  //   setEstatus([true])
  // }
  // const ninguno = () =>{
  //   setEstatus([false])
  // }


      React.useEffect(() => {
        busqueda()
      }, []);


const CalcularDiferencia = (fechaInicio: any, fechaFin: any, devolverMinutos: boolean) => {
  // Crear los objetos moment
  const inicio = moment(fechaInicio);
  const fin = moment(fechaFin);

  // Calcular la diferencia en minutos
  const diferenciaMinutos = fin.diff(inicio, 'minutes');

  // Si devolverMinutos es verdadero, retornar solo la diferencia en minutos
  if (devolverMinutos) {
    return diferenciaMinutos;
  }

  // Si la diferencia es menor de 60 minutos, solo mostrar los minutos
  if (diferenciaMinutos < 60) {
    return `${diferenciaMinutos} minutos`;
  }

  // Convertir la diferencia en horas y minutos
  const horas = Math.floor(diferenciaMinutos / 60);
  const minutos = diferenciaMinutos % 60;

  // Si la diferencia es mayor a 60 minutos, mostrar en horas y minutos
  return `${horas} horas y ${minutos} minutos`;
};

const obtenerClaseTrabajo = (tiempoEnMinutos: any) => {
  if (tiempoEnMinutos >= 0 && tiempoEnMinutos <= 240) {
    return 'trabajo_poco';  // 0 - 4 horas
  } else if (tiempoEnMinutos >= 241 && tiempoEnMinutos <= 359) {
    return 'trabajo_mediano';  // 4 horas 1 minuto hasta 5 horas 59 minutos
  } else if (tiempoEnMinutos >= 360 && tiempoEnMinutos <= 419) {
    return 'trabajo_bien';  // 6 horas hasta 6 horas 59 minutos
  } else if (tiempoEnMinutos >= 420 && tiempoEnMinutos <= 479) {
    return 'trabajo_excelente';  // 7 horas hasta 7 horas 59 minutos
  } else if (tiempoEnMinutos >= 480) {
    return 'trabajo_goat';  // 8 horas o más
  }

  return '';  // En caso de que no encaje en ningún rango
};







  const [ show_ModalRegistroIncidencias, setModalRegistroIncidencias ] = useState<boolean>(false)
  const handleShow_ModalRegistroIncidencias = () => {
    setModalRegistroIncidencias(true)
  }
  const handleClose_ModalRegistroIncidencias = () => {
    setModalRegistroIncidencias(false)
  }



  const [ show_ModalReporteIncidencias, setModalReporteIncidencias ] = useState<boolean>(false)
  const handleShow_ModalReporteIncidencias = () => {
    setModalReporteIncidencias(true)
  }
  const handleClose_ModalReporteIncidencias = () => {
    setModalReporteIncidencias(false)
  }




      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Asistencias de Personal</h4>
                    <hr/>




                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        







                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5 className="mt-4">Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (registro_asistencia:any,indice_registro:number) =>
                                                  <tr key={indice_registro} >
                                                    <td className="v text-left">
                                                        <h3 className="nm">{registro_asistencia.nombres}</h3>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"260px"}}>
                                                        <p className="nm" style={{fontSize:17}}>{registro_asistencia.paterno} {registro_asistencia.materno}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"260px"}}>
                                                        <p className="nm">{registro_asistencia.celular}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"260px"}}>
                                                        <p className="nm">{registro_asistencia.actividad}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm"><b>{moment(registro_asistencia.fecha_inicio).format('DD/MMMM/YYYY').replace('.','').toUpperCase()}</b></p>
                                                        <small>{moment(registro_asistencia.fecha_inicio).format('hh:mm A')}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <img alt="" src={`${SERVER_}asistencia/foto_entrada/${registro_asistencia.uuid_asistencia}`} width={100} height={100} />
                                                    </td>
                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm"><b>{moment(registro_asistencia.fecha_fin).format('DD/MMMM/YYYY').replace('.','').toUpperCase()}</b></p>
                                                        <small>{moment(registro_asistencia.fecha_fin).format('hh:mm A')}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <img alt="" src={`${SERVER_}asistencia/foto_salida/${registro_asistencia.uuid_asistencia}`} width={100} height={100} />
                                                    </td>
                                                    <td className={`v text-left ${obtenerClaseTrabajo(CalcularDiferencia(registro_asistencia.fecha_inicio,registro_asistencia.fecha_fin,true))}`} style={{"width":"160px"}}>
                                                      <p className="nm">{CalcularDiferencia(registro_asistencia.fecha_inicio,registro_asistencia.fecha_fin,false)}</p>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                    
                </div>
              </div>

            </div>








  {
    show_ModalRegistroIncidencias && (
      <ModalRegistroIncidencias show={show_ModalRegistroIncidencias} handleClose={handleClose_ModalRegistroIncidencias}  />
      )

  }


  {
    show_ModalReporteIncidencias && (
      <ModalReporteIncidencias show={show_ModalReporteIncidencias} handleClose={handleClose_ModalReporteIncidencias}  />
      )

  }









          </>

      );
  

}

export default Asistencias
