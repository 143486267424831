export const rol = (rol:string)=>{
    let r = '----'
    if(rol === 'ROLE_ADMIN') r = 'ADMINISTRADOR'

    if(rol === 'ROL_REGION') r = 'RESPONSABLE DE REGIÓN'
    if(rol === 'ROL_MICROREGION') r = 'RESPONSABLE DE MICROREGIÓN'
    if(rol === 'ROL_MUNICIPAL') r = 'RESPONSABLE MUNICIPAL'
    if(rol === 'ROL_ZONA') r = 'RESPONSABLE DE ZONA'
    if(rol === 'ROL_AREA') r = 'CAPTURISTA'


    return r
}