import React, { useState, useRef, useEffect } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'

import moment from 'moment'

import { toast } from 'react-toastify'


import { Map, View } from 'ol';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Point } from 'ol/geom';
import { Icon, Style } from 'ol/style';
import { Feature } from 'ol';
import { WKT } from 'ol/format';
import 'ol/ol.css'; // Importa los estilos de OpenLayers
import { transform } from 'ol/proj';  // Importa la función para transformar coordenadas
import BingMaps from 'ol/source/BingMaps.js';


import Text from 'ol/style/Text';

import { Polygon } from 'ol/geom';
import { transformExtent } from 'ol/proj'; // Para transformar la extensión del polígono
import { Draw } from 'ol/interaction';
import { Extent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';

// Asegúrate de importar 'Polygon' de OpenLayers, que es necesario para trabajar con polígonos.
import { Geometry } from 'ol/geom';


import { Fill, Stroke } from 'ol/style'; // Asegúrate de importar estas clases



const ModalReporteIndividual = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, usuario, fecha_inicio, fecha_fin } = params


  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 15
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ reporte, setResultados ] = useState<any>({...skull})



  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`usuario/reporte_individual/${usuario.uuid}/${moment(fecha_inicio).format('YYYY-MM-DD')}/${moment(fecha_fin).format('YYYY-MM-DD')}?skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const [ puntos, setPuntos ] = useState<any>([])
  const [ extension, setExtension ] = useState<any>('POLYGON ((-10131772.38597333 2119812.8317677337, -10120290.153183423 2119681.142753743, -10120173.919280259 2129541.7791351806, -10131661.88486967 2129674.169059353, -10131772.38597333 2119812.8317677337))')
  const cargarPuntos = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`usuario/reporte_individual_puntos/${usuario.uuid}/${moment(fecha_inicio).format('YYYY-MM-DD')}/${moment(fecha_fin).format('YYYY-MM-DD')}`)
      setPuntos(response.data.resultados)
      setExtension(response.data.extension)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }



  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
        cargarPuntos()
      }, []);



  useEffect(() => {

    const estadoLayer = new TileLayer({
                        visible: true,
                        preload: Infinity,
                        source: new BingMaps({
                          key: 'AlfLvHHE5n4P-noAIj3F2EnWEQmkjTxfa-CW9eJwlkwsJloyEbP-fsdNDVyA6AmT',
                          imagerySet: 'Aerial',
                          // placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
                        }),
                      })


    const mapInstance = new Map({
      target: mapRef.current,
      layers: [
        estadoLayer,
new VectorLayer({
  source: new VectorSource({
    features: puntos.map((puntoData: any) => {
      const wkt = new WKT();
      const point = wkt.readGeometry(puntoData.punto);
      const feature = new Feature(point);

      // Agregar el "folio" como un atributo de la feature
      feature.set('folio', puntoData.folio);

      // Estilo con imagen y texto
      feature.setStyle(
        new Style({
          image: new Icon({
            src: 'img/marker.png',
            scale: 0.7,
          }),
          text: new Text({
            text: puntoData.folio,  // Aquí usas el "folio" del punto
            scale: 1,             // Tamaño del texto
            offsetY: -25,           // Posicionar el texto por encima del icono
            fill: new Fill({
              color: '#000',        // Color del texto
            }),
            stroke: new Stroke({
              color: '#fff',        // Color del contorno del texto (para mejorar la legibilidad)
              width: 2,
            }),
          }),
        })
      );

      return feature;
    }),
  }),
}),

      ],
      view: new View({
        projection: 'EPSG:3857',
        center: fromLonLat([19.8656367, -90.497437]),
        zoom: 10,
      }),
    });

    // Si hay una extensión, dibujarla en el mapa
    if (extension) {
      const wkt = new WKT();
      const geometry: Geometry = wkt.readGeometry(extension); // Convertir el WKT a geometría
      const extent = geometry.getExtent(); // Obtener el extent del polígono

      // Usamos la extensión para ajustar la vista del mapa
      mapInstance.getView().fit(extent, { padding: [20, 20, 20, 20] });

    }

    setMap(mapInstance);

    // mapRef.current = mapInstance

    return () => mapInstance.setTarget(undefined);
  }, [puntos, extension]);


  const mapRef: any = useRef(null);
  const [map, setMap] = useState<any>(null);

const moveToCoordinates = (coordinates: any, zoom: number) => {
  // Asegúrate de que `map` no es nulo
  if (map) {
    const wkt = new WKT();
    const geometry: any = wkt.readGeometry(coordinates); // Convertir el WKT a geometría

    // Mover al centro de las coordenadas y hacer zoom
    map.getView().setCenter(geometry.flatCoordinates);
    map.getView().setZoom(zoom);
  }
};

  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte Individual</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        {
          moment(fecha_inicio).format('YYYY-MM-DD') === moment(fecha_fin).format('YYYY-MM-DD') && (
              <>
                <h4>{moment(fecha_inicio).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')}</h4>
              </>
            )
        }
        {
          moment(fecha_inicio).format('YYYY-MM-DD') !== moment(fecha_fin).format('YYYY-MM-DD') && (
              <>
                <h4>ENTRE {moment(fecha_inicio).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')} Y EL {moment(fecha_fin).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')}</h4>
              </>
            )
        }

        <div className="row">
          <div className="col-sm-4">
            <small>Capturó</small>
            <h6>{usuario.nombres} {usuario.paterno} {usuario.materno}</h6>
          </div>
          <div className="col-sm-2">
            <small>Folios </small>
            <h5>{usuario.adultos+usuario.discapacidad}</h5>
          </div>
          <div className="col-sm-2">
            <small>PAM 👴🏻</small>
            <h5>{usuario.adultos}</h5>
          </div>
          <div className="col-sm-2">
            <small>PCD ♿️</small>
            <h5>{usuario.discapacidad}</h5>
          </div>
          <div className="col-sm-2">
            <small>Vivientas 🏠</small>
            <h5>{usuario.viviendas}</h5>
          </div>
        </div>
{/*
          WHERE fecha_censo BETWEEN '{moment(fecha_inicio).format('YYYY-MM-DD')}' AND '{moment(fecha_inicio).format('YYYY-MM-DD')}' 
and idusuario in (select id from usuarios where uuid = {JSON.stringify(usuario.uuid)})
*/}
          {
            // JSON.stringify(puntos)
          }


                                       <div className="row">
                                         <div className="col">
                                           <div ref={mapRef} style={{ width: '100%', height: '700px' }}></div>
                                         </div>
                                         <div className="col-4">


                          {  (!cargando_) && (

                              <>

                                   {
                                     reporte.length > 0 && (

                                       <>


                                                <Table responsive="lg">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Folio</th>
                                                      <th scope="col">PAM</th>
                                                      <th scope="col">PCD</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {
                                                      reporte.map( (folio:any,indice:number) =>
                                                          <tr onClick={(e:any)=>{moveToCoordinates(folio.punto,19)}} key={indice}   style={{fontSize:12}}>
                                                            <td className="v text-left" >
                                                                <p className="nm"><small><b>{folio.folio}</b></small></p>
                                                                {/*{folio.punto}*/}
                                                            </td>
                                                            <td className="v text-left" style={{"width":"50px"}}>
                                                                  <p className="nm">{ folio.adulto ? 'SI' : '' }</p>
                                                            </td>
                                                            <td className="v text-left" style={{"width":"50px"}}>
                                                                  <p className="nm">{ folio.discapacidad ? 'SI' : '' }</p>
                                                            </td>
                                                          </tr>
                                                       )
                                                    }

                                                  </tbody>
                                                </Table>


                                                  <Pagination
                                                    itemsCount={allSessionsCount}
                                                    itemsPerPage={sessionsPerPage}
                                                    currentPage={currentPage}
                                                    setCurrentPage={setCurrentPage}
                                                    cambiarPaginaEvento={cargarMiReporte}
                                                    alwaysShown={false}
                                                  />
                                           



                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                                         </div>
                                       </div>




        </Modal.Body>
      </Modal>
    </>
  );
}







// interface MapComponentProps {
//   puntos: any[];
//   extension : string;
// }

/*
const MapComponent: React.FC<MapComponentProps> = ({ puntos, extension }) => {
  const mapRef: any = useRef(null);
  const [map, setMap] = useState<any>(null);

  useEffect(() => {

    const estadoLayer = new TileLayer({
                        visible: true,
                        preload: Infinity,
                        source: new BingMaps({
                          key: 'AlfLvHHE5n4P-noAIj3F2EnWEQmkjTxfa-CW9eJwlkwsJloyEbP-fsdNDVyA6AmT',
                          imagerySet: 'Aerial',
                          // placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
                        }),
                      })


    const mapInstance = new Map({
      target: mapRef.current,
      layers: [
        estadoLayer,
        new VectorLayer({
          source: new VectorSource({
            features: puntos.map((puntoData: any) => {
              const wkt = new WKT();
              const point = wkt.readGeometry(puntoData.punto);
              const feature = new Feature(point);
              feature.setStyle(
                new Style({
                  image: new Icon({
                    src: 'img/marker.png',
                    scale: .06,
                  }),
                })
              );
              return feature;
            }),
          }),
        }),
      ],
      view: new View({
        projection: 'EPSG:3857',
        center: fromLonLat([19.8656367, -90.497437]),
        zoom: 10,
      }),
    });

    // Si hay una extensión, dibujarla en el mapa
    if (extension) {
      const wkt = new WKT();
      const geometry: Geometry = wkt.readGeometry(extension); // Convertir el WKT a geometría
      const extent = geometry.getExtent(); // Obtener el extent del polígono

      // Usamos la extensión para ajustar la vista del mapa
      mapInstance.getView().fit(extent, { padding: [20, 20, 20, 20] });

    }

    setMap(mapInstance);

    return () => mapInstance.setTarget(undefined);
  }, [puntos, extension]);

  return <div ref={mapRef} style={{ width: '100%', height: '700px' }}></div>;
};

*/

export default ModalReporteIndividual;