import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'

import ModalNuevoRegistroCenso from './ModalNuevoRegistroCenso'



import ModalDetalleFolio from '../Censo/ModalDetalleFolio'

// import './censo.scss';


const MisFolios = () => {

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`capturista/mis_folios/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([true])


  const todos = () =>{
    setEstatus([true])
  }
  const ninguno = () =>{
    setEstatus([false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);




  const [ show_ModalNuevoRegistroCenso, setModalNuevoRegistroCenso ] = useState<boolean>(false)
  const handleShow_ModalNuevoRegistroCenso = () => {
    setModalNuevoRegistroCenso(true)
  }
  const handleClose_ModalNuevoRegistroCenso = () => {
    setModalNuevoRegistroCenso(false)
  }


  const [ show_ModalDetalleFolio, setModalDetalleFolio ] = useState<boolean>(false)
  const [ folio_seleccionado, setFolioSeleccionado ] = useState<any>(null)
  const handleShow_ModalDetalleFolio = (folio:any) => {
    setFolioSeleccionado(folio)
    setModalDetalleFolio(true)
  }
  const handleClose_ModalDetalleFolio = () => {
    busqueda()
    setModalDetalleFolio(false)
  }


      return (
          <>
            <div className="container-fluid">


                    <h4 className="mt-4">Mis Folios</h4>
                    <hr/>


                    <div className="row">
                    
                        <div className="mb-2">
                            <label className="form-label">Búsqueda</label>
                            <div className="hstack gap-3">
                              <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                              <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                              <div className="vr"></div>
                              <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                            </div>
                        </div>
                        
                        
                      <ul className="list-inline">
                        <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalNuevoRegistroCenso()}}>Nuevo Registro</small></li>
                      </ul>

                    </div>




                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col">Folio</th>
                                              <th scope="col">Captura</th>
                                              <th scope="col">Migración</th>
                                              <th scope="col">Sexo</th>
                                              <th scope="col">Región</th>
                                              <th scope="col">Microregión</th>
                                              <th scope="col">Zona</th>
                                              <th scope="col">Área</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (folio:any,indice_folio:number) =>
                                                  <tr key={indice_folio}>
                                                    <td className="v text-left pointer" onClick={(e:any)=>{handleShow_ModalDetalleFolio(folio)}}>
                                                            
                                                        <h3 className="nm"><small><b>{folio.folio}</b></small></h3>

                                                    </td>
                                                    <td className="v text-left" style={{"width":"250px"}}>
                                                        <p className="nm">{new Date(folio.fecha_censo).toLocaleString()}</p>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"250px"}}>
                                                        <p className="nm">{new Date(folio.fecha_creacion).toLocaleString()}</p>
                                                    </td>

                                                    <td className="v text-left" style={{"width":"160px"}}>
                                                        <p className="nm">{folio.sexo==='H'?'HOMBRE':'MUJER'} </p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <p className="nm">{folio.region}</p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <p className="nm">{folio.microregion}</p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <p className="nm">{folio.zona}</p>
                                                    </td>
                                                    <td className="v text-center" style={{"width":"60px"}}>
                                                        <p className="nm">{folio.seccion}</p>
                                                    </td>
                                                    <td className={`v text-center ${folio.adulto?'adulto':''}`} style={{"width":"60px",fontSize:30}}>
                                                      {
                                                        folio.adulto && (<> { folio.sexo === 'H' ? <>👴🏻</> : <>👵🏻</> } </>)
                                                      }
                                                    </td>
                                                    <td className={`v text-center ${folio.discapacidad?'discapacidad  ':''}`} style={{"width":"60px",fontSize:20}}>
                                                      {
                                                        folio.discapacidad && (<>♿️</>)
                                                      }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"80px"}}>
                                                      <button className="btn bt-link" onClick={(e:any)=>{handleShow_ModalDetalleFolio(folio)}}><i className="fa fa-archive"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                    
            </div>







  {
    show_ModalNuevoRegistroCenso && (
      <ModalNuevoRegistroCenso show={show_ModalNuevoRegistroCenso} handleClose={handleClose_ModalNuevoRegistroCenso}  />
      )

  }





  {
    show_ModalDetalleFolio && (
      <ModalDetalleFolio show={show_ModalDetalleFolio} handleClose={handleClose_ModalDetalleFolio} folio={folio_seleccionado} />
      )

  }






          </>

      );
  

}

export default MisFolios
