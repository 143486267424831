import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import moment from 'moment'

import { SERVER_ } from '../../config'

const ModalReporteAvancesCensoExcel = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // const descargaReporteAvanceExcel = () => {
  //   // window.open(`${SERVER_}censo/folios/reporte/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)
  // }
const [ cargando_ , setCargando ] = useState<boolean>(false)
const descargaReporteAvanceExcel = async () => {
    try {
        // Solicita el token de seguridad
        // const token = 'tu_token_de_seguridad';

        setCargando(true)

        // Realiza la solicitud GET con el token en el encabezado
        const response = await fetch(`${SERVER_}censo/folios/reporte/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${AuthService.getToken()}`
            }
        });

        // Verifica si la respuesta es exitosa
        if (!response.ok) {
            throw new Error('Error al descargar el archivo');
        }

        // Convierte la respuesta en un blob
        const blob = await response.blob();

        // Crea una URL para el archivo
        const urlArchivo = window.URL.createObjectURL(blob);

        // Crea un enlace para iniciar la descarga
        const enlace = document.createElement('a');
        enlace.href = urlArchivo;

        let nombreArchivo = `reporte_avance_censo_${moment(startDate).format('YYYY_MM_DD_HH_mm')}.xlsx`; // Nombre por defecto

        enlace.download = nombreArchivo;
        document.body.appendChild(enlace);
        enlace.click();

        // Limpia el URL del archivo y el enlace creado
        window.URL.revokeObjectURL(urlArchivo);
        document.body.removeChild(enlace);
        setCargando(false)


    } catch (error) {
        setCargando(false)
        console.error('Error:', error);
    }
}


  const calculateDateRange = (option: string) => {
    const today = new Date();
    let start: Date;
    let end: Date = new Date();

    switch (option) {
      case 'SemanaActual':
        // Lunes de la semana actual hasta hoy
        start = new Date(today);
        start.setDate(today.getDate() - today.getDay() + 1); // Ajuste para el lunes de la semana
        end = today;
        break;

      case 'SemanaPasada':
        // Lunes de la semana pasada hasta el domingo de la semana pasada
        start = new Date(today);
        start.setDate(today.getDate() - today.getDay() - 6); // Lunes de la semana pasada
        end = new Date(start);
        end.setDate(start.getDate() + 6); // Domingo de la semana pasada
        break;

      case 'MesActual':
        // Desde el primer día del mes hasta hoy
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = today;
        break;

      case 'MesPasado':
        // Desde el primer día del mes pasado hasta el último día del mes pasado
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0); // Último día del mes pasado
        break;

      case 'DesdeElInicio':
        // Desde el 1 de octubre hasta hoy
        start = new Date(today.getFullYear(), 9, 1); // Octubre es el mes 9 (0-based)
        end = today;
        break;

      case 'Ultimos15Dias':
        // Desde hace 15 días hasta hoy
        start = new Date(today);
        start.setDate(today.getDate() - 15);
        end = today;
        break;

      default:
        return;
    }

    // Actualizamos las fechas en el estado
    setStartDate(start);
    setEndDate(end);
  };



  return (
    <>
      <Modal show={show} size="sm" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Avances</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(user)
          }

      <p>Filtro fijos:</p>
      <ul className="list-group list-group-flush mb-4">
        <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('SemanaActual')}>Semana Actual</button></li>
        <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('SemanaPasada')}>Semana Pasada</button></li>
        <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('MesActual')}>Mes Actual</button></li>
        <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('MesPasado')}>Mes Pasado</button></li>
        <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('DesdeElInicio')}>Desde el Inicio</button></li>
        <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('Ultimos15Dias')}>Últimos 15 Días</button></li>
      </ul>



            <DatePicker
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              maxDate={new Date()}
              endDate={endDate}
              selectsRange
              inline
            />


            { cargando_ && <p className="text-center mt-4"><img src="img/loadcat.gif" /></p>}



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" disabled={cargando_} onClick={handleClose}>
            Cerrar
          </Button>
          <Button variant="success" type="button" disabled={cargando_} onClick={descargaReporteAvanceExcel}>Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalReporteAvancesCensoExcel;