import React, { useState, useEffect, useRef } from 'react';
import 'ol/ol.css';
import BingMaps from 'ol/source/BingMaps.js';
import { Map, View } from 'ol';
import ImageLayer from 'ol/layer/Image';
import Image from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';

import { Navbar, Container, Row, Col, Button, Tab, Tabs } from "react-bootstrap";
import './posicionamiento.scss';  // Importa el archivo CSS





import RequestService from "../../services/request.service";


import { Form, Modal, InputGroup } from 'react-bootstrap'

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import AuthService from '../../features/auth/auth.service'

// import Dialog from '../../services/Dialogs'

import moment from 'moment'




const PosicionamientoRegional = () => {


  const user = AuthService.getCurrentUser()

const PUNTOS_MUNICIPIOS = [
  [ 19.32921513257443, -89.8356343629373 ],
  [ 18.720374942523996, -91.13975220647896 ]
  ]

  const coordinates = PUNTOS_MUNICIPIOS[user.region-1].reverse();

  const transformedCoordinates = fromLonLat(coordinates, 'EPSG:3857');

  const estadoLayerRef = useRef<any>(null);

  const distritosLayerRef = useRef<any>(null);
  const seccionesLayerRef = useRef<any>(null);





  const zonasPrioridadLayerRef = useRef<any>(null);

  const heatmapEncuestaSalidaLayerRef = useRef<any>(null);
  const bienestarAvanceSeccionesLayerRef = useRef<any>(null);


  const coloniasLayerRef = useRef<any>(null);


  const mapRef = useRef<any>(null);

  const [bbox, setBbox] = useState<string | null>(null);


  useEffect(() => {


    const estadoLayer_ = new TileLayer({
                          source: new OSM(),
                        });





    const estadoLayer = new TileLayer({
                        visible: true,
                        preload: Infinity,
                        source: new BingMaps({
                          key: 'AlfLvHHE5n4P-noAIj3F2EnWEQmkjTxfa-CW9eJwlkwsJloyEbP-fsdNDVyA6AmT',
                          imagerySet: 'Aerial',
                          // placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
                        }),
                      })





    // Capa para "secciones"
    const seccionesLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/areas_regiones.map',
        params: {
          'LAYERS': `region_${user.region}`,
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });



    // Capa para "secciones_avance_censo"
    const bienestarAvanceSeccionesLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/bienestar_avance_capas_region.map',
        params: {
          'LAYERS': `bienestar_avance_capas_region_${user.region}`,
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });




    ////////////////////////////////////////////
    ////////////////////////////////////////////


    /// HEATMAPS LAYERS


    // Capa para "encuesta de salida"
    const heatmapEncuestaSalidaLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/heatmap_censo_bienestar_region.map',
        params: {
          'LAYERS': `heatmap_${user.region}`,
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });



    /// OTRAS LAYERS


    // Capa para "encuesta de salida"
    const coloniasLayer = new ImageLayer({
      source: new ImageWMS({
        url: window.location.protocol+'//'+window.location.hostname+'/cgi-bin/mapserv?map=/var/www/wms/colonias.map',
        params: {
          'LAYERS': 'colonias',
          'FORMAT': 'image/png',
          'TRANSPARENT': true,
          'VERSION': '1.3.0',
          'CRS': 'EPSG:3857',
        },
        serverType: 'mapserver',
      }),
    });






    // Guardar las capas en las referencias
    estadoLayerRef.current = estadoLayer;

    seccionesLayerRef.current = seccionesLayer;

    heatmapEncuestaSalidaLayerRef.current = heatmapEncuestaSalidaLayer;
    bienestarAvanceSeccionesLayerRef.current = bienestarAvanceSeccionesLayer;




    coloniasLayerRef.current = coloniasLayer;

    const map = new Map({
      target: 'map', // ID del elemento donde se renderizará el mapa
      layers: [
                  estadoLayer, 
                  bienestarAvanceSeccionesLayer,

                  coloniasLayer,



                  seccionesLayer, 


                  heatmapEncuestaSalidaLayer,

              ],
      // [



      // ],
            view: new View({
                center: transformedCoordinates,
                zoom: 8.5
              }),
    });

    mapRef.current = map


    const getBoundingBox = () => {
      const view = map.getView();
      const extent = view.calculateExtent(map.getSize());
      return extent;
    };

    // Escuchar los cambios en el mapa (zoom, movimiento)
    map.on('moveend', () => {
      const bbox = getBoundingBox();
      setBbox(bbox.join(', ')); // Actualizar el estado del BBOX
      // console.log('BBOX actual:', bbox); // O mostrarlo en consola
    });


    return () => map.setTarget(undefined); // Limpieza al desmontar el componente
  }, []);


  const toggleLayer = (layerRef:any, visible:any) => {
    if (layerRef.current) {
      layerRef.current.setVisible(visible);
    }
  };



  const [ capas, setCapas ] = useState<any>({

    estado : true,

    distritos : false,

    secciones : false,

    regiones : false,
    microregiones : false,
    zonas : false,
    zonas_federales : false,


    zonas_prioridad : false,

    heatmap_encuesta_salida : false,
    avance_censo : true,



    colonias : false,

  }) 

  // Efecto para controlar la visibilidad de las capas
  useEffect(() => {
    toggleLayer(seccionesLayerRef, capas.secciones);
    toggleLayer(distritosLayerRef, capas.distritos);
    toggleLayer(estadoLayerRef, capas.estado);



    toggleLayer(zonasPrioridadLayerRef, capas.zonas_prioridad);

    toggleLayer(heatmapEncuestaSalidaLayerRef, capas.heatmap_encuesta_salida);
    toggleLayer(bienestarAvanceSeccionesLayerRef, capas.avance_censo);



    toggleLayer(coloniasLayerRef, capas.colonias);

  }, [capas]);


  const moveToCoordinates = (coordinates:any, zoom: number) => {
    const newCoordinates = fromLonLat( coordinates , 'EPSG:3857');
    if (mapRef.current) {
      mapRef.current.getView().setCenter(newCoordinates);
      mapRef.current.getView().setZoom(zoom);
    }
  };













  // const dialog = new Dialog();





  const [ mapa_base, setMapaBase ] = useState('osm')



  const [ text_busqueda, setTextoBusqueda ] = useState<any>('')

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const busquedaSeccion = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPointPost(`posicionamiento/busqueda/seccion`, { seccion : text_busqueda })
      moveToCoordinates( [ response.data.localizacion.lon, response.data.localizacion.lat ] ,17)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  return (

      <React.Fragment>


      <Container fluid className="main-container">
        <Row className="h-100">
          {/* Columna izquierda para los controles y capas */}
          <Col xs={12} md={3} >


                      <h5>Controles del Mapa</h5>



                      {/*<h6>Mapa Base</h6>*/}

                      {/*<Form.Check type={'radio'} onChange={(e:any)=>{ setMapaBase('osm') }} checked={mapa_base==='osm'} label={`OpenStreetMap`} id={`disabled-default-osm`} />*/}
                      {/*<Form.Check type={'radio'} onChange={(e:any)=>{ setMapaBase('bing') }} checked={mapa_base==='bing'} label={`BING Aerial`} id={`disabled-default-bing`} />*/}



                      <Tabs
                        defaultActiveKey="shapes"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey="shapes" title="Capas">


                            <h6>Interés</h6>


                                <label>
                                <input type="checkbox" checked={capas.secciones} onChange={(e:any)=>{ let c = {...capas}; c.secciones = e.target.checked; setCapas(c) }} />
                                {' '}
                                  Áreas
                                </label>


                                <label>
                                <input type="checkbox" checked={capas.heatmap_encuesta_salida} onChange={(e:any)=>{ let c = {...capas}; c.heatmap_encuesta_salida = e.target.checked; setCapas(c) }} />
                                {' '}
                                  Zonas de calor Censo Bienestar 2024
                                </label>
                                
                                <label>
                                <input type="checkbox" checked={capas.avance_censo} onChange={(e:any)=>{ let c = {...capas}; c.avance_censo = e.target.checked; setCapas(c) }} />
                                {' '}
                                  Avance Censo Bienestar 2024
                                </label>
                                



                            <h6 className="mt-2">Geografía</h6>





                                <label>
                                <input type="checkbox" checked={capas.colonias} onChange={(e:any)=>{ let c = {...capas}; c.colonias = e.target.checked; setCapas(c) }} />
                                {' '}
                                  Colonias
                                </label>





{/*
                                    <ul className="list-inline">
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 20.372442563356547, -90.05011005727538 ].reverse(),14) }} className="pointer list-inline-item"><small>CALKINÍ</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 19.846320337199660, -90.52897668399505 ].reverse(),13) }} className="pointer list-inline-item"><small>CAMPECHE</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 18.649779569881044, -91.81618290894278 ].reverse(),14) }} className="pointer list-inline-item"><small>CARMEN</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 19.344617384422364, -90.72444042305112 ].reverse(),14) }} className="pointer list-inline-item"><small>CHAMPOTÓN</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 20.177110062277247, -90.13413143087266 ].reverse(),14) }} className="pointer list-inline-item"><small>HECELCHAKÁN</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 19.745665590597067, -89.84364540452793 ].reverse(),14) }} className="pointer list-inline-item"><small>HOPELCHÉN</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 18.255096138598546, -92.08875955577535 ].reverse(),15) }} className="pointer list-inline-item"><small>PALIZADA</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 20.042436812777700, -90.22964693003085 ].reverse(),15) }} className="pointer list-inline-item"><small>TENABO</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 18.60676905361087, -90.74034900154219 ].reverse(),14) }} className="pointer list-inline-item"><small>ESCÁRCEGA</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 18.503001691637476, -89.3948840931363 ].reverse(),12) }} className="pointer list-inline-item"><small>CALAKMUL</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 18.187241006772066, -91.04135128435782 ].reverse(),12) }} className="pointer list-inline-item"><small>CANDELARIA</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 19.64027806588473, -90.68310250545628 ].reverse(),12) }} className="pointer list-inline-item"><small>SEYBAPLAYA</small></li>
                                      <li onClick={(e:any)=>{ moveToCoordinates([ 20.320827937568477, -90.05636557497681 ].reverse(),12) }} className="pointer list-inline-item"><small>DZITBALCHÉ</small></li>
                                    </ul>
*/}




                                    


                                    <br/>





                        </Tab>
                        <Tab eventKey="busqueda" title="Búsqueda" >


                          <Form.Label>Búsqueda de Área</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control placeholder="Área" onKeyDown={(e)=>{if(e.keyCode === 13){ busquedaSeccion() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                            <InputGroup.Text onClick={(e:any)=>{busquedaSeccion()}} className="pointer">🔎</InputGroup.Text>
                          </InputGroup>


                        </Tab>
                      </Tabs>






                          
                          
 


          </Col>
          <Col xs={12} md={9}>

            <div id="map" style={{ width: '100%', height: '100%', minHeight : 500 }} />

          </Col>
        </Row>
      </Container>



        

      </React.Fragment>


    );
};

export default PosicionamientoRegional
