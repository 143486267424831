import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';


import Dialog from '../../services/Dialogs'


const ModalNuevoUsuario = ( params : any ) => {

  const { show, handleClose } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          curp : yup.string().required(mensajes_validacion_generico),
          celular : yup.number().required(mensajes_validacion_generico),
          nombres : yup.string().required(mensajes_validacion_generico),
          paterno : yup.string().required(mensajes_validacion_generico),
          materno : yup.string(),
          rol : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)

          if(registro.region === "") registro.region = null
          if(registro.microregion === "") registro.microregion = null
          if(registro.zona === "") registro.zona = null
          if(registro.area === "") registro.area = null

          let res_ = await RequestService.create( `usuarios/agregar_usuario` , { ...registro })

          if(res_){
            toast.success('El usuario fué agregado. Ahora puede acceder al sistema')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }









  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nuevo Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }



    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            curp : ``,
            // curp : `${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${Math.floor(Math.random() * 10)}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${'ABCDEFGHIJKLMNÑOPQRSTUVWXYZ'[Math.floor(Math.random() * 20)]}${Math.floor(Math.random() * 10)}`,
            celular : '',
            nombres : "",
            paterno : "",
            materno : "",
            rol : "ROL_AREA",


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="curp">
              <Form.Label>CURP</Form.Label>
              <Form.Control
                required
                name="curp"
                value={values.curp}
                onBlur={(e)=>{ setFieldValue('curp', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('curp', e.target.value ) }}
                isInvalid={!!errors.curp}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.curp}</>} </Form.Control.Feedback>
            </Form.Group>



            <Form.Group className="mb-3" controlId="curp">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                required
                name="celular"
                value={values.celular}
                onBlur={(e)=>{ setFieldValue('celular', e.target.value ) }}
                onChange={(e)=>{ setFieldValue('celular', e.target.value ) }}
                isInvalid={!!errors.celular}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.curp}</>} </Form.Control.Feedback>
            </Form.Group>



            <Form.Group className="mb-3" controlId="nombres">
              <Form.Label>Nombre(s)</Form.Label>
              <Form.Control
                required
                name="nombres"
                value={values.nombres}
                onBlur={(e)=>{ setFieldValue('nombres', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('nombres', e.target.value ) }}
                isInvalid={!!errors.nombres}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.nombres}</>} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="paterno">
              <Form.Label>Paterno</Form.Label>
              <Form.Control
                required
                name="paterno"
                value={values.paterno}
                onBlur={(e)=>{ setFieldValue('paterno', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('paterno', e.target.value ) }}
                isInvalid={!!errors.paterno}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.paterno}</>} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="materno">
              <Form.Label>Materno</Form.Label>
              <Form.Control
                required
                name="materno"
                value={values.materno}
                onBlur={(e)=>{ setFieldValue('materno', e.target.value.toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('materno', e.target.value ) }}
                isInvalid={!!errors.materno}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.materno}</>} </Form.Control.Feedback>
            </Form.Group>










        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar Ventana
          </Button>
          <Button variant="success" form='my-form' disabled={envio_server} type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevoUsuario;