import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { formatNumberWithCommas, getPercentageClass } from '../../services/funcionesPublicas'

import './avances.scss'

const Municipios = () =>{


  const [ avance, setAvance ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarSeccion = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`reportes/avances/municipios`)
      setAvance(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }



  React.useEffect(() => {
    cargarSeccion()
  }, []);




      return (
          <>

                    <h6>Municipios</h6>
                    <hr/>


                        <h3>Avance en Municipios</h3>
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Región</th>
                              <th>Municipio</th>
                              <th>Responsable</th>
                              <th>Áreas</th>
                              <th className="">Meta</th>
                              <th className="">Folios</th>
                              <th className="adulto">🧓🏿</th>
                              <th className="adulto">Meta</th>
                              <th className="discapacidad">♿️</th>
                              <th className="discapacidad">Meta</th>
                              <th className="viviendas">🏠</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              avance.map((municipio:any,indice_municipio:number) => (
                              <tr key={municipio.id}>
                                <td className="v text-center" style={{width:80}}>
                                  <p className="nm">{municipio.region}</p>
                                </td>
                                <td className="v" >
                                  <p className="nm">{municipio.municipio}</p>

                                </td>
                                <td className="v" style={{width:400}}>
                                  <p className="nm">
                                    {
                                      municipio.responsable ? <><b>{municipio.responsable.nombres}</b> {municipio.responsable.paterno} {municipio.responsable.materno}</> : ''
                                    }
                                    {
                                      // JSON.stringify(municipio.responsable)
                                    }
                                  </p>
                                </td>
                                <td className="v text-center" style={{width:130}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.secciones)}</p>
                                </td>
                                <td className="v" style={{width:130}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta)}</p>
                                </td>
                                <td className={`v ${getPercentageClass(( ((municipio.folios_pam + municipio.folios_pcd) * 100) / (municipio.meta) ))}`} style={{width:150}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.folios_pam + municipio.folios_pcd)} <small>({Math.round( ((municipio.folios_pam + municipio.folios_pcd) * 100) / (municipio.meta) )}%)</small></p>
                                {
                                  // getPercentageClass(municipio.folios_pam)

                                }
                                </td>
                                <td className="v adulto" style={{width:130}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.folios_pam)}</p>
                                </td>
                                <td className="v adulto" style={{width:130}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta_pam)}</p>
                                </td>
                                <td className="v discapacidad" style={{width:130}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.folios_pcd)}</p>
                                </td>
                                <td className="v discapacidad" style={{width:130}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.meta_pcd)}</p>
                                </td>
                                <td className="v viviendas" style={{width:130}}>
                                  <p className="nm">{formatNumberWithCommas(municipio.viviendas)}</p>
                                </td>
                              </tr>
                            ))
                            }
                          </tbody>
                        </table>

                      <p className="nm text-center">
                        <small>
                            entre 0 y 10% <i className="fa fa-circle" style={{color:'rgb(255 0 0)'}}/> {' '}
                            entre 10 y 30%  <i className="fa fa-circle" style={{color:'rgb(255 165 0)'}}/>{' '}
                            entre 30 y 50%  <i className="fa fa-circle" style={{color:'rgb(255 255 0)'}}/>{' '}
                            entre 50 y 70%  <i className="fa fa-circle" style={{color:'rgb(173 255 47)'}}/>{' '}
                            entre 70 y 90%  <i className="fa fa-circle" style={{color:'rgb(0 128 0)'}}/>{' '}
                            entre 90 y 100%  <i className="fa fa-circle" style={{color:'rgb(0 255 0)'}}/>
                        </small>
                      </p>

                        <p>
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_municipios.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=secciones_avance_censo,estado&CRS=EPSG%3A3857&WIDTH=1686&HEIGHT=1042&BBOX=-10425162,2003624,-9782964,2384944`} />
                        <small>Estado de Campeche</small>
                        </p>




                    <div className="row">
                      <div className="col-4">
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_municipios.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=secciones_avance_censo,estado&CRS=EPSG%3A3857&WIDTH=1686&HEIGHT=1042&BBOX=-10082762,2250097,-10068559,2258531`} />
                        <small>Ciudad de San Francisco de Campeche</small>
                      </div>
                      <div className="col-4">
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_municipios.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=secciones_avance_censo,estado&CRS=EPSG%3A3857&WIDTH=1686&HEIGHT=1042&BBOX=-10225621,2110302,-10213863,2117284`} />
                        <small>Ciudad del Carmen</small>
                      </div>
                      <div className="col-4">
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_municipios.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=secciones_avance_censo,estado&CRS=EPSG%3A3857&WIDTH=1686&HEIGHT=1042&BBOX=-10115803,2185057,-10079378,2206686`} />
                        <small>Ciudad de Champotón</small>
                      </div>
                    </div>








                        <p>
                        <img width="100%" src={`${window.location.protocol}//${window.location.hostname}/cgi-bin/mapserv?map=/var/www/wms/reporte_municipios.map&REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=heatmap,estado&CRS=EPSG%3A3857&WIDTH=1768&HEIGHT=1029&BBOX=-10402920.752440684%2C2012651.0487111323%2C-9784817.899477284%2C2372395.2996722963`} />
                        <small>Estado de Campeche</small>
                        </p>







                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>


          </>

      );
  

}

export default Municipios
