import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import { Tabs, Tab } from 'react-bootstrap';

import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';


import CalendarHeatmap from 'react-calendar-heatmap';
import { Tooltip  as ReactTooltip } from 'react-tooltip';
import 'react-calendar-heatmap/dist/styles.css';


import moment from 'moment'
import 'moment/locale/es'

import './avances.scss'

const AvanceArea = () => {


  const mapRef : any = useRef();

  useEffect(() => {
    const map = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
    });

    return () => map.setTarget(undefined); // Limpia el mapa al desmontar
  }, []);


      // const [ registro, setRegistro ] = useState<any>([])

      // const cargar = async () =>{
      //   try{
      //       let res = await RequestService.getEndPoint(`URL_REQUEST`)
      //       setRegistro(res.data)

      //   }
      //   catch(e){
      //     toast.error('Problemas cargando')
      //     toast.error(JSON.stringify(e))
      //   }
      // }

      // React.useEffect(() => {
      //   cargar()
      // }, []);
const data : any = [
  { fecha: '2023-01-01', valor: 10 },
  { fecha: '2023-01-15', valor: 20 },
  { fecha: '2023-02-01', valor: 30 },
  { fecha: '2023-02-15', valor: 40 },
  { fecha: '2023-03-01', valor: 50 },
  { fecha: '2023-03-15', valor: 60 },
];
  const [tooltipContent, setTooltipContent] = useState<any>('');

  const handleMouseOver = (value: any) => {
    if (value) {
      setTooltipContent(`${value.date}: ${value.count} contribuciones`);
    } else {
      setTooltipContent('Sin contribuciones');
    }
  };

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Mi Avance</h4>
                    <hr/>
                    

                    <div className="row">
                      <div className="col-4">



                          <Tabs
                            defaultActiveKey="avance"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                          >
                            <Tab eventKey="avance" title="Avance">
                              <h6>Mi Avance</h6>


      <CalendarHeatmap
        startDate={new Date('2024-09-01')}
        endDate={new Date('2024-12-31')}
        values={data}
        classForValue={(value) => {
          if (!value) {
            return 'color-empty';
          }
          return `color-scale-${value.count}`;
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={() => setTooltipContent('')}
      />
      <ReactTooltip content={tooltipContent} />



    <LineChart width={400} height={300} data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="fecha" />
      <YAxis />
      <Tooltip />
      <Line type="monotone" dataKey="valor" stroke="#82ca9d" />
    </LineChart>




                            </Tab>
                            <Tab eventKey="profile" title="Profile">
                              Tab content for Profile
                            </Tab> 
                          </Tabs>


                      </div>
                      <div className="col">
                        <div
                          ref={mapRef}
                          style={{ width: '100%', height: '600px' }}
                        />
                      </div>
                    </div>




                </div>
              </div>

            </div>

          </>

      );
  

}

export default AvanceArea
