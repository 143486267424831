import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import { Routes, Route, NavLink, Outlet, useLocation } from 'react-router-dom';



import moment from 'moment'
import 'moment/locale/es'

const Avances = () => {

      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Avances</h4>

                    <nav >
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item active"><a href="/avances/regiones">REGIONES</a></li>
                        <li className="breadcrumb-item active"><a href="/avances/municipios">MUNICIPIOS</a></li>
                        <li className="breadcrumb-item active"><a href="/avances/zonas">ZONAS</a></li>
                      </ol>
                    </nav>
               



                    


              <Outlet />





                </div>
              </div>

            </div>

          </>

      );
  

}

export default Avances
