import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

import { Table } from 'react-bootstrap'

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import { formatNumberWithCommas } from '../../services/funcionesPublicas'
import { rol } from '../../services/rol'

import { Form } from 'react-bootstrap'


import ModalReporteIndividual from './ModalReporteIndividual'


const Censo_Reporte = () =>{



  const [startDate_seleccionada, setStartDateSeleccionada] = useState(new Date());
  const [endDate_seleccionada, setEndDateSeleccionada] = useState(new Date());

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  const [ reportecapturistas, setReportecapturistas ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarReportecapturistas = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPointPost(`avances/por_usuario/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, { arreglo_estatus : arreglo_estatus, where : text_busqueda  } )
      setReportecapturistas(response.data)
      setStartDateSeleccionada(startDate)
      setEndDateSeleccionada(endDate)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    // calculateDateRange('MesActual')
    cargarReportecapturistas()
  }, []);



  const calculateDateRange = (option: string) => {
    const today = new Date();
    let start: Date;
    let end: Date = new Date();

    switch (option) {
      case 'Hoy':
        // Lunes de la semana actual hasta hoy
        start = new Date();
        end = new Date();
        break;

      case 'SemanaActual':
        // Lunes de la semana actual hasta hoy
        start = new Date(today);
        start.setDate(today.getDate() - today.getDay() + 1); // Ajuste para el lunes de la semana
        end = today;
        break;

      case 'SemanaPasada':
        // Lunes de la semana pasada hasta el domingo de la semana pasada
        start = new Date(today);
        start.setDate(today.getDate() - today.getDay() - 6); // Lunes de la semana pasada
        end = new Date(start);
        end.setDate(start.getDate() + 6); // Domingo de la semana pasada
        break;

      case 'MesActual':
        // Desde el primer día del mes hasta hoy
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = today;
        break;

      case 'MesPasado':
        // Desde el primer día del mes pasado hasta el último día del mes pasado
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0); // Último día del mes pasado
        break;

      case 'DesdeElInicio':
        // Desde el 1 de octubre hasta hoy
        start = new Date(today.getFullYear(), 9, 1); // Octubre es el mes 9 (0-based)
        end = today;
        break;

      case 'Ultimos15Dias':
        // Desde hace 15 días hasta hoy
        start = new Date(today);
        start.setDate(today.getDate() - 15);
        end = today;
        break;

      default:
        return;
    }

    // Actualizamos las fechas en el estado
    setStartDate(start);
    setEndDate(end);
  };


  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ arreglo_estatus, setEstatus ] = useState<any>([false,false,false])





  const [ show_ModalReporteIndividual, setModalReporteIndividual ] = useState<boolean>(false)
  const [ usuario_seleccionado, setUsuarioSeleccionado ] = useState<any>(null)
  const handleShow_ModalReporteIndividual = (usuario:any) => {
    setUsuarioSeleccionado(usuario)
    setModalReporteIndividual(true)
  }
  const handleClose_ModalReporteIndividual = () => {
    setModalReporteIndividual(false)
  }



      return (
          <>
            <div className="container-fluid">


                    <h4>Reporte de Avances del Censo 2024</h4>
                    <hr/>


                    <div className="row">
                        <div className="col-md-2">

                          <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            selectsRange
                            inline
                          />



                          <div className="mb-3">
                            <label htmlFor="exampleInputEmail1" className="form-label">Búsqueda Fina</label>
                            <input type="text" className="form-control" id="exampleInputEmail1" onKeyDown={(e)=>{if(e.keyCode === 13){ cargarReportecapturistas() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                          </div>

                          <div>
                            <Form.Check name="group1" type="checkbox" label={rol('ROL_AREA')} checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                            <Form.Check name="group1" type="checkbox" label={rol('ROL_ZONA')} checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                            <Form.Check name="group1" type="checkbox" label={rol('ROL_MUNICIPAL')} checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                          </div>


                          <button className="btn mb-2 btn-primary  w-100" style={{width:'100%'}} onClick={cargarReportecapturistas}>Cargar Reporte</button>





                            <p>Filtro fijos:</p>
                            <ul className="list-group list-group-flush mb-4">
                              <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('Hoy')}>Hoy</button></li>
                              <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('SemanaActual')}>Semana Actual</button></li>
                              <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('SemanaPasada')}>Semana Pasada</button></li>
                              <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('MesActual')}>Mes Actual</button></li>
                              <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('MesPasado')}>Mes Pasado</button></li>
                              <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('DesdeElInicio')}>Desde el Inicio</button></li>
                              <li className="list-group-item"><button className="btn btn-link btn-sm" onClick={() => calculateDateRange('Ultimos15Dias')}>Últimos 15 Días</button></li>
                            </ul>


                        </div>
                        <div className="col-md-10">

                                {
                                  envio_server && (
                                      <React.Fragment>
                                        Cargando...
                                      </React.Fragment>
                                    )
                                }


                                {
                                  !envio_server && (
                                      <React.Fragment>


                                            {
                                              reportecapturistas.length > 0 && (
                                                  <React.Fragment>

                                                    {
                                                      moment(startDate_seleccionada).format('YYYY-MM-DD') === moment(endDate_seleccionada).format('YYYY-MM-DD') && (
                                                          <>
                                                            <h4>{moment(startDate_seleccionada).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')}</h4>
                                                          </>
                                                        )
                                                    }
                                                    {
                                                      moment(startDate_seleccionada).format('YYYY-MM-DD') !== moment(endDate_seleccionada).format('YYYY-MM-DD') && (
                                                          <>
                                                            <h4>ENTRE {moment(startDate_seleccionada).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')} Y EL {moment(endDate_seleccionada).format('DD [DE] MMMM [DE] YYYY').toUpperCase().replace('.','')}</h4>
                                                          </>
                                                        )
                                                    }


                                                      <Table responsive="lg" size="sm" className="table-hover">
                                                        <thead>
                                                          <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col" colSpan={1}>Capturista</th>
                                                            <th className="text-center" scope="col">Folios</th>
                                                            <th className="text-center adulto" scope="col">👴🏻</th>
                                                            <th className="text-center discapacidad" scope="col">♿️</th>
                                                            <th className="text-center vivienda" scope="col">Viviendas</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {
                                                            reportecapturistas.map((capturista:any,indice_capturista:number)=>

                                                                <tr className={`${ capturista.activo ? '' : 'table-row-disabled' } ${ capturista.folios === 0 ? 'table-danger' : '' } `} key={indice_capturista}>
                                                                  {/*<th style={{width:150}} scope="row">{capturista.celular}</th>*/}

                                                                  <td className="v" style={{width:200}}>
                                                                      <small>{rol(capturista.rol)}</small>
                                                                  </td>
                                                                  <td className="v pointer" onClick={(e:any)=>{handleShow_ModalReporteIndividual(capturista)}}>
                                                                      <p className="nm"><b>{capturista.nombres}</b> <small>{capturista.paterno} {capturista.materno}</small></p>
                                                                  </td>
                                                                  <td style={{width:70}} className="v text-center"><p className="nm">{capturista.folios}</p></td>
                                                                  <td style={{width:70}} className="v text-center adulto"><p className="nm">{capturista.adultos}</p></td>
                                                                  <td style={{width:70}} className="v text-center discapacidad"><p className="nm">{capturista.discapacidad}</p></td>
                                                                  <td style={{width:70}} className="v text-center vivienda"><p className="nm">{capturista.viviendas}</p></td>
                                                                </tr>

                                                              )
                                                          }
                                                        </tbody>
                                                      </Table>

                                              {
                                                // JSON.stringify(reportecapturistas)
                                              }

                                                  </React.Fragment>
                                                )
                                            }




                                            {
                                              reportecapturistas.length === 0 && (
                                                  <React.Fragment>
                                                      <div className="alert alert-warning" role="alert">
                                                        Para esta fecha no existe reporte
                                                      </div>
                                                  </React.Fragment>
                                                )
                                            }




                                      </React.Fragment>
                                    )
                                }

                        </div>
                    </div>


            </div>






  {
    show_ModalReporteIndividual && (
      <ModalReporteIndividual show={show_ModalReporteIndividual} handleClose={handleClose_ModalReporteIndividual} usuario={usuario_seleccionado} fecha_inicio={startDate} fecha_fin={endDate} />
      )

  }






          </>

      );
  

}

export default Censo_Reporte
