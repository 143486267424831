import React, { useState } from 'react';
import RequestService from "../../services/request.service";
import AuthService from '../../features/auth/auth.service';
import { Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Formik, FieldArray, FormikErrors } from 'formik';
import * as yup from 'yup';
import Dialog from '../../services/Dialogs';

import BarcodeScanner from 'react-qr-barcode-scanner';

import './captura.scss';

interface Folio {
  folio: string;
  sexo: 'H' | 'M' | '';
  adulto: boolean;
  discapacidad: boolean;
  modal_scan: boolean;
}

interface FormValues {
  foto_casa: string;
  foto_marca: string;
  folios: Folio[];
}

interface FormErrors {
  foto_casa?: string;
  foto_marca?: string;
  folios?: FormikErrors<Folio>[];
}




const ModalNuevoRegistroCenso = (params: any) => {
  const user = AuthService.getCurrentUser();
  const { show, handleClose } = params;

  const [location, setLocation] = useState<{ latitude: number | null; longitude: number | null }>({ latitude: null, longitude: null });
  const [error, setError] = useState<string | null>(null);

const getLocation = () => {
  if (navigator.geolocation) {
    navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
      if (permissionStatus.state === 'granted') {
        // Permiso ya concedido
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            setError(error.message);
          }
        );
      } else if (permissionStatus.state === 'prompt') {
        // El navegador pedirá permiso
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        // Permiso denegado
        toast.error('Debes asignar permisos de localización y tener activo esta para poder capturar');
        handleClose();
        setError("Geolocation permission denied.");
      }
    }).catch((error) => {
        toast.error('Debes asignar permisos de localización y tener activo esta para poder capturar');
        handleClose();
      setError("Error checking geolocation permission: " + error.message);
    });
  } else {
        toast.error('Debes asignar permisos de localización y tener activo esta para poder capturar');
        handleClose();
    setError("Geolocation is not supported by this browser.");
  }
};

React.useEffect(() => {
  getLocation();
}, []);

  const dialog = new Dialog();
  const mensajes_validacion_generico = "Este campo es obligatorio";
const schema = yup.object().shape({
  foto_casa: yup.string().required(mensajes_validacion_generico),
  foto_marca: yup.string().required(mensajes_validacion_generico),
  folios: yup.array().of(
    yup.object().shape({
      folio: yup.string().required(mensajes_validacion_generico),
      sexo: yup.string().required(mensajes_validacion_generico),
      adulto: yup.boolean(),
      discapacidad: yup.boolean(),
      modal_scan: yup.boolean(),
    })
  ).min(1, "Debes agregar al menos un folio").required(),
});


  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const enviarFormulario = async (registro: any, event: any) => {
    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro de la información?',
      // target: event.target,
      template: '',
    });

    let dialogo: any = await dialog.waitForUser();
    if (dialogo) {
      try {
        let res_ = await RequestService.create(`migrar_censo`, { ...registro, localizacion : location });
        if (res_) {
          toast.success('El registro de Censo fue creado correctamente');
          setTimeout(() => {
            handleClose();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e: any) {
        console.log(e)
        toast.error(e.response.data);
      }
    }
  };

const isFormikError = (error: any): error is FormikErrors<Folio> => {
  return typeof error === 'object' && error !== null && !Array.isArray(error);
};


  const [showScan, setShowScan] = useState(false);

  const handleCloseScan = () => setShowScan(false);
  const handleShowScan = () => setShowScan(true);

  const [ resultado_scan, setResultadoScan ] = useState<any>()

  const handleScan = (data: string) => {
    setResultadoScan(data);
    // Aquí puedes procesar el resultado como necesites
  };





  const [texto, setTexto] = useState('');

  const manejarPegado = async (e:any) => {
    // Intentar pegar el contenido del portapapeles en un campo de texto
    try {
      // En dispositivos móviles, debemos usar un evento de usuario para acceder al portapapeles.
      const textoPeleado = await navigator.clipboard.readText();
      setTexto(textoPeleado);
    } catch (error) {
      console.error('Error al pegar el texto desde el portapapeles:', error);
      // Si no se puede acceder al portapapeles (por ejemplo, en navegadores más antiguos),
      // podríamos tener un fallback como un input de tipo "textarea" o "input".
    }
  };

 


  return (
    <>
      <Modal show={show} fullscreen={true} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            <small>Nuevo Registro de Censo</small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {
          // JSON.stringify(location)
        }
{/*          {location.latitude && location.longitude ? (
            <div>
              <h2>Tu ubicación:</h2>
              <p>Latitud: {location.latitude}</p>
              <p>Longitud: {location.longitude}</p>
            </div>
          ) : (
            <p>Ubicación no disponible</p>
          )}
          {error && <p>Error: {error}</p>}*/}

          <Formik
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              await enviarFormulario(values, null);
              setSubmitting(false);
            }}
            initialValues={{
              foto_casa: '',
              foto_marca: '',
              folios: [{ folio: '', sexo: '', adulto: false, discapacidad: false, modal_scan : false }] as Folio[],
            }}
          >
            {({
              handleSubmit,
              setFieldValue,
              values,
              touched,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>



         <Row className="mb-2">
            <Col className="col-sm-6">
              {
                values.foto_casa !== '' && (<><img src={values.foto_casa} width="100%" /><small onClick={(e:any)=>{setFieldValue('foto_casa',"")}}><i className="fa fa-trash"/></small></>)
              }

              {
                values.foto_casa === '' && (<>
                      <Form.Group controlId="formFotoCasa">
                        <Form.Label>Foto de la Casa</Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          capture
                      onChange={async (event) => {
                        const target = event.target as HTMLInputElement; // Type assertion
                        if (target.files && target.files[0]) {
                          const base64 = await convertToBase64(target.files[0]);
                          setFieldValue("foto_casa", base64);
                        }
                      }}
                        />
                      </Form.Group>
                </>)
              }
            </Col>
            <Col className="col-sm-6">
              {
                values.foto_marca !== '' && (<><img src={values.foto_marca} width="100%" /><small onClick={(e:any)=>{setFieldValue('foto_marca',"")}}><i className="fa fa-trash"/></small></>)
              }

              {
                values.foto_marca === '' && (<>
                      <Form.Group controlId="formFotoMarca">
                        <Form.Label>Foto de la Marca</Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/*"
                          capture
                      onChange={async (event) => {
                        const target = event.target as HTMLInputElement; // Type assertion
                        if (target.files && target.files[0]) {
                          const base64 = await convertToBase64(target.files[0]);
                          setFieldValue("foto_marca", base64);
                        }
                      }}
                        />
                      </Form.Group>
                </>)
              }


            </Col>
          </Row>



                <FieldArray name="folios">
                  {({ insert, remove }) => (
                    <>
                      <Button
                        variant="primary" className="mt-2" size="sm"
                        onClick={() => insert(values.folios.length, { folio: '', sexo: '', adulto: false, discapacidad: false, modal_scan : false })}
                      >
                        <small>Agregar Folio</small>
                      </Button>

                      {
                        // JSON.stringify(values.folios)
                      }

                      {values.folios.map((folio: Folio, index: number) => (
                        <div key={index} style={{ marginTop: '10px' }}>


                              <table className="table">
                              <tbody>
                                <tr>
                                  <td >

                                    <Modal show={folio.modal_scan} fullscreen={true} onHide={() => setFieldValue(`folios.${index}.modal_scan`, false)}> 
                                      <Modal.Body style={{padding:0}}>

                                                <div className="scanner-container">
                                                  <BarcodeScanner onUpdate={(err:any, result:any) => {
                                                            if (result){
                                                              setFieldValue(`folios.${index}.folio`, result.text)
                                                              setFieldValue(`folios.${index}.modal_scan`, false)
                                                            }
                                                          }}  />
                                                  <div className="overlay">
                                                    <div className="scanner-area">
                                                      <p>Apunta aquí</p>
                                                    </div>
                                                  </div> 


                                                  <button className="btn btn-xl w-100" type="button" onClick={async(e:any)=>{


                                                  // async (err:any, result:any)  => {
 
                                                                  try {
                                                                    // En dispositivos móviles, debemos usar un evento de usuario para acceder al portapapeles.
                                                                    const textoPeleado = await navigator.clipboard.readText();
                                                                    setFieldValue(`folios.${index}.folio`, textoPeleado)
                                                                    setFieldValue(`folios.${index}.modal_scan`, false)

                                                                  } catch (error) {
                                                                    alert('Error al pegar el texto desde el portapapeles');
                                                                  }

                                                            
                                                          // }


                                                  }}>PEGAR CÓDIGO</button>
                                                </div>

                               
                                      </Modal.Body> 
                                    </Modal>

                                      <Button size="sm" className="w-100" onClick={() => setFieldValue(`folios.${index}.modal_scan`, true)}> { folio.folio === "" ? <i className="fa fa-barcode"/> : <>{folio.folio}</> }</Button>

                                  </td>
                                  <td style={{width:70,textAlign:'center',verticalAlign:'middle'}}>
                                    <Form.Check
                                      type="checkbox"
                                      label={ (folio.sexo === '' ? <span onClick={(e:any)=>{setFieldValue(`folios.${index}.sexo`, "H")}} className="nm text-center">❓</span> : ( folio.sexo === 'M' ? <span onClick={(e:any)=>{setFieldValue(`folios.${index}.sexo`, "H")}} className="nm text-center">👵🏻</span> : <span onClick={(e:any)=>{setFieldValue(`folios.${index}.sexo`, "M")}} className="nm">👴🏻</span> ) )  }
                                      name={`folios.${index}.adulto`}
                                      checked={folio.adulto}
                                      onChange={(e) => setFieldValue(`folios.${index}.adulto`, e.target.checked)}
                                    />
                                     

                                  </td>
                                  <td style={{width:70,textAlign:'center',verticalAlign:'middle'}}>
                                    <Form.Check
                                      type="checkbox"
                                      label="♿️"
                                      name={`folios.${index}.discapacidad`}
                                      checked={folio.discapacidad}
                                      onChange={(e) => setFieldValue(`folios.${index}.discapacidad`, e.target.checked)}
                                    />
                                  </td>
                                  <td style={{width:50}}>
                                    <Button variant="danger" size="sm" onClick={() => remove(index)}><i className="fa fa-trash"/></Button>
                                  </td>
                                </tr>
                              </tbody>
                              </table>


                       
                          
                        </div>
                      ))}

      {/*<pre>{JSON.stringify({ errors, touched }, null, 2)}</pre>*/}



                    </>
                  )}
                </FieldArray>


              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">
            Guardar <i className="fa fa-save" />
          </Button>
        </Modal.Footer>
      </Modal>










    </>
  );
}

export default ModalNuevoRegistroCenso;
