import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'

const Zonas = () =>{

      // const [ registro, setRegistro ] = useState<any>([])

      // const cargar = async () =>{
      //   try{
      //       let res = await RequestService.getEndPoint(`URL_REQUEST`)
      //       setRegistro(res.data)

      //   }
      //   catch(e){
      //     toast.error('Problemas cargando')
      //     toast.error(JSON.stringify(e))
      //   }
      // }

      // React.useEffect(() => {
      //   cargar()
      // }, []);


      return (
          <>

                    <h4>Zonas</h4>
                    <hr/>

          </>

      );
  

}

export default Zonas
