export enum ROLE {
  ADMIN = "ROLE_ADMIN",

  ROL_REGION = "ROL_REGION",
  ROL_MICROREGION = "ROL_MICROREGION",
  ROL_MUNICIPAL = "ROL_MUNICIPAL",
  ROL_ZONA = "ROL_ZONA",
  ROL_AREA = "ROL_AREA",

}


