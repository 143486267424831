import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


const ModalRegistroIncidencias = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Registro de Incidencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalRegistroIncidencias;