import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


const ModalReporteIncidencias = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params

  return (
    <>
      <Modal show={show} fullsize={true} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Incidencias</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReporteIncidencias;